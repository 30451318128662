var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        size: "large",
        "ok-text": "下一步",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "table_class" }, [
          _c("div", { staticClass: "select_content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("待选人员"),
              _c("span", { staticClass: "title_tip" }, [
                _vm._v("根据人员姓名搜索显示、点击头像可选中"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "table_select" },
              [
                _c("a-input-search", {
                  attrs: { placeholder: "请输入人员姓名", "enter-button": "" },
                  on: { search: _vm.onSearch },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "select_content_avatar" }, [
              _vm.contentArr && _vm.contentArr.length > 0
                ? _c(
                    "div",
                    { staticClass: "select_content_flex" },
                    _vm._l(_vm.contentArr, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "avatar",
                          on: {
                            click: function ($event) {
                              return _vm.selectUse(item)
                            },
                          },
                        },
                        [
                          _c("CbAvatar", {
                            attrs: {
                              "on-job-id": item.onJobId,
                              "avater-name": item.staffName,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", [_c("CbNoData", { attrs: { width: "75" } })], 1),
            ]),
          ]),
        ]),
        _vm.resultVisible
          ? _c("div", { staticClass: "select_class" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("已选人员"),
                _c("span", { staticClass: "title_tip" }, [
                  _vm._v("点击头像可取消选中"),
                ]),
              ]),
              _c("div", { staticClass: "select_content_avatar" }, [
                _vm.selectArr && _vm.selectArr.length > 0
                  ? _c(
                      "div",
                      { staticClass: "select_content_flex" },
                      _vm._l(_vm.selectArr, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "avatar",
                            on: {
                              click: function ($event) {
                                return _vm.selectUseClose(item)
                              },
                            },
                          },
                          [
                            _c("CbAvatar", {
                              attrs: {
                                "on-job-id": item.onJobId,
                                "avater-name": item.staffName,
                                size: "30",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("div", [_c("CbNoData", { attrs: { width: "75" } })], 1),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }